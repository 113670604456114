import React from 'react'
import { formatTotalWithUnit } from '../utils/formatters'

class CoOffset extends React.Component {
    state = {

    }

    calcValue = () => {
        const { stations, station, clientId, overview } = this.props
        {/* NOTE: Checking for station offset value of total pumped */}
        // let useOffset = offsetClients.indexOf(clientId) > -1
        let ignoreOffset = ['4328', '4329', '4241', '5021', '5281', '5220', '5616', '5618', '6294']
        let useOffset = true
        let value = 0
        if (stations) {
            value = stations.reduce((accum, t) => {
                if (ignoreOffset.indexOf(t.get('id') > -1)) {
                    useOffset = false
                }
                let val = t.get('NT_TotalFuel') ? parseInt(t.get('NT_TotalFuel').replace(' gal').trim(), 10) : 0
                if (t.get('Offset_NT_TotalFuel')) {
                    val = val - parseInt(t.get('Offset_NT_TotalFuel'), 10)
                }
                let total = accum + val
                return total
            }, 0)
            // always add our offset here
            if (useOffset) {
                console.log('using offset')
                value = value + 77513.58
            }
        } else if (station) {
            value = station.get('NT_TotalFuel') ? parseInt(station.get('NT_TotalFuel').replace(' gal').trim(), 10) : 0
            if (station.get('Offset_NT_TotalFuel')) {
                value = value - parseInt(station.get('Offset_NT_TotalFuel'), 10)
            }
            if (ignoreOffset.indexOf(station.get('id')) === -1) {
                console.log('using offset')
                value = value + 77513.58
            }
        }
        // SEE ABOVE: Adding 77513.58 to account for a counter reset on 5/18/2022 BUT ignore for 4328 & 4329 (new stations)
        // Adding 77513.58 to account  overview for a counter reset on 5/18/2022
        // Adding 22020 to offset for deletion of ADM SMARTfuel station on 9/1/2022
        // console.log('value before:  ', value)
        // 61677
        if (overview) {
            console.log('using offset  for overview')
            value = value + 77513.58
            value = value + 22020
        }
        // console.log('value after: ', value)
        // 553190.5800000001
        let calc = value !== 0 ? value * -18.80206 : 0
        // return Math.round(calc).toLocaleString('en-US')
        return formatTotalWithUnit(calc)
    }

    render = () => {
        return (
            <div className="widget">
                <h3 className="border-bottom">CO2 Offset</h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ this.calcValue() }</span>
                        <label>Pounds</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default CoOffset
